<template>
  <nav class="toolbar" style="height: 64px">
    <div class="flex layout navLayout fill-height">
      <div class="flex md4">
        <div class="nav layout fill-height justify-start align-center text-xs-left">
          <div class="navItem text-uppercase">
            <span style="font-size: 16px">« </span>
            <a class="router-link-active" v-if="showDefault" :href="defaultRoute.path">
              <span v-t="'Back to'"></span> <span>{{ defaultRoute.name }}</span>
            </a>
            <router-link v-if="!showDefault" :to="nextRoute" class="router-link-active">
              <span v-t="'Back to'"></span> <span>{{ nextRoute.name }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="flex xs10 md4">
        <div class="layout justify-center align-self-center fill-height navLogo">
          <a href="https://www.rewardstyle.com/" class="router-link-active">
            <rs-logo-ltk class="fill-height" style="color: white; fill: white" width="100" />
          </a>
        </div>
      </div>
      <div class="flex xs1 md4"></div>
    </div>
  </nav>
</template>
<script lang="ts">
import Vue, { computed, defineComponent, getCurrentInstance } from 'vue';
import RsLogoLtk from '@/components/RsLogoLtk.vue';

export default defineComponent({
  name: 'NavigationBar',
  components: {
    RsLogoLtk,
  },
  setup() {
    const vm = getCurrentInstance();
    const { $route } = vm?.proxy as Vue;

    const customRouting = [
      {
        name: 'ForgotPassword',
        next: 'Login',
      },
      {
        name: 'ConfirmEmail',
        next: 'Login',
      },
    ];

    const defaultRoute = {
      name: 'home',
      path: 'https://www.rewardstyle.com/',
    };

    const nextRoute = computed(() => {
      let next = customRouting.find((route) => route.name === $route.name);
      return next ? { name: next.next } : defaultRoute;
    });

    const showDefault = computed(() => customRouting.filter((route) => route.name === $route.name).length === 0);

    return {
      defaultRoute,
      nextRoute,
      showDefault,
    };
  },
});
</script>

<style lang="scss">
.toolbar {
  background-color: black;
  color: white;
}

.router-link-active {
  > span {
    color: white;
    font-weight: 500;
    letter-spacing: 2px !important;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;

    > span {
      color: white;
      font-weight: bold;
    }
  }

  &:focus {
    outline: 0;
    color: #ccc;

    > span {
      color: #ccc;
    }
  }
}

.nav {
  align-items: center;
  display: flex;
  height: 100%;
  text-transform: uppercase;
}

.navItem {
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  color: white;
  flex: 0 0 auto;
  margin-left: 30px;
  text-align: left;
  text-decoration-color: white;
  text-decoration-line: none;
  text-decoration-style: solid;
  user-select: none;
}

.navLayout {
  height: 100%;
  line-height: 64px;
}

.navLogo {
  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
