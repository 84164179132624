import _Vue from 'vue';
import rsUiLibrary from '@rscollabs/rs-ui-library';

import { RsContainer, RsApp, RsFlex, RsLayout, RsTextField, RsBtn } from '@rscollabs/rs-ui-library/src/components';

export default (Vue: typeof _Vue) => {
  Vue.use(rsUiLibrary, {
    components: { RsContainer, RsApp, RsFlex, RsLayout, RsTextField, RsBtn },
  });
};
