import _Vue from 'vue';
import * as amplitude from '@amplitude/analytics-browser';

const Amplitude = {
  install: function (Vue: typeof _Vue) {
    const key = process.env.VUE_APP_AMPLITUDE_API_KEY;

    if (key) {
      amplitude.init(key, {
        transport: 'beacon',
        flushIntervalMillis: 0,
        flushQueueSize: 1,
        defaultTracking: true,
      });

      Vue.prototype.$amplitude = amplitude;
    }
  },
};

export default (Vue: typeof _Vue) => {
  Vue.use(Amplitude);
};

declare module 'vue/types/vue' {
  interface Vue {
    $amplitude?: typeof amplitude;
  }
}
