<template>
  <rs-app light id="app">
    <navigation-bar></navigation-bar>
    <router-view :key="$route.fullPath" ref="routerView" />
  </rs-app>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted } from 'vue';
import NavigationBar from '@/components/NavigationBar.vue';

export default defineComponent({
  name: 'App',
  components: {
    NavigationBar,
  },
  setup() {
    const vm = getCurrentInstance();
    const { $amplitude } = vm?.proxy as Vue;

    onMounted(() => {
      $amplitude?.track('/page/login');
    });
  },
});
</script>

<style lang="scss">
html {
  overflow-y: auto;
}

body {
  font-family: var(--body-font);
  font-style: normal !important;
  background: #fafafa;
}

.v-content {
  background-color: #fcfcfc;
  height: 100%;
}

.v-content__wrap {
  height: 100%;
  flex: 1 1 auto;
  display: flex;
}

button[inverted] {
  margin-bottom: 12px;
  margin-top: 18px;
  border: none !important;
}

.v-text-field {
  input {
    font-size: 18px !important;
    letter-spacing: normal;
  }
}

.v-btn {
  text-decoration: none !important;
  border-radius: 6px !important;
  border-color: #d3d3d3 !important;

  .v-btn__content {
    font-size: 12px !important;
    font-weight: 600 !important;
    text-decoration: none !important;
  }
}
</style>
