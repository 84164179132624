export type EmptyType = string | null;

/**
 * Intended to be a function for an array filter.
 * Will return true if the fiven item is
 * - non-null
 * - non-0 length string or array
 */

export const isEmpty = (value: EmptyType | EmptyType[]) => {
  if (typeof value === 'string') {
    return value === null || value.trim().length === 0;
  }
  return value === null || value.length === 0;
};

export const isNotEmpty = (value: EmptyType | EmptyType[]) => !isEmpty(value);
