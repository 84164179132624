import { RsFormComponent } from '@/types';

/**
 * Validation function for form components which is easy to mock
 * in unit testing.
 *
 * @param form
 */
export const validation = (form: RsFormComponent | null | undefined): boolean => {
  return !!form?.validate();
};
