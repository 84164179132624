import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { castToVueI18n, createI18n } from 'vue-i18n-bridge';
import translations from '@/translations.json';

type Locales = 'en_US' | 'fr_FR' | 'de_DE' | 'zh-Hans' | 'zh-Hant' | 'es_ES' | 'it_IT' | 'pt_BR' | 'ko_KR';
type SupportedLanguages = { [locale in Locales]: string };

export const availableLanguages: SupportedLanguages = {
  en_US: 'English',
  fr_FR: 'French - Français',
  de_DE: 'German - Deutsch',
  'zh-Hans': 'Simplified Chinese - 中文 (简体)',
  'zh-Hant': 'Traditional Chinese - 中文 (繁體)',
  es_ES: 'Spanish - Español',
  it_IT: 'Italian - Italiano',
  pt_BR: 'Portuguese - Português',
  ko_KR: 'Korean - 한국어',
};

const languageCodeMap: SupportedLanguages = {
  en_US: 'en__US',
  fr_FR: 'fr',
  de_DE: 'de_DE',
  'zh-Hans': 'zh-Hans',
  'zh-Hant': 'zh-Hant',
  es_ES: 'es_ES',
  it_IT: 'it',
  pt_BR: 'pt_BR',
  ko_KR: 'ko',
};

const languageMap = (language: string): Locales | undefined => {
  // Just return the language if it's valid
  if (language in availableLanguages) {
    return language as Locales;
  }

  if (language === 'zh-TW' || language === 'zh-CN') {
    return 'zh-Hant';
  }

  if (language === 'pt-BR') {
    return 'pt_BR';
  }

  const baseLanguage = language.split(/[-_]/)[0];

  // Try to match the two letter lang code (for example 'en', 'it') to available languages
  const lang = Object.keys(availableLanguages).find((languageKey) => languageKey.startsWith(baseLanguage));
  return (lang as Locales) ?? undefined;
};

// Given an existing array of language codes try to map it to a specific locale
// because some locales are two letters, while others are four with an underscore separator
const mapToLanguageCode = (code: readonly string[]) => {
  const language = code.map(languageMap);

  if (language.length > 0 && (language[0] as Locales) in languageCodeMap) {
    return languageCodeMap[language[0] as Locales];
  }
};

const defaultLanguage = (): string => {
  // Try Auth0 language url param
  const urlSearchParams = new URLSearchParams(window.location.search);
  const uiLocales = urlSearchParams.get('ui_locales');

  if (uiLocales) {
    const lang = mapToLanguageCode(uiLocales.split(' '));

    if (lang) {
      return lang;
    }
  }

  // Legacy lang param
  const langParam = urlSearchParams.get('lang');

  if (langParam) {
    const lang = mapToLanguageCode(langParam.split(' '));

    if (lang) {
      return lang;
    }
  }

  // Try browser language
  const browserLanguages = navigator.languages ? navigator.languages : [navigator.language];
  const lang = mapToLanguageCode(browserLanguages);

  if (lang) {
    return lang;
  }

  // Return default
  return languageCodeMap['en_US'];
};

Vue.use(VueI18n, { bridge: true });

const i18n = castToVueI18n(
  createI18n(
    {
      legacy: false,
      locale: defaultLanguage(),
      messages: translations,
    },
    VueI18n,
  ),
);

Vue.use(i18n);

export default i18n;
