import Vue from 'vue';
import i18n from './i18n';
import router from './router';
import App from './App.vue';
import registerPlugins from './plugins';

import './style/main.scss';

registerPlugins(Vue);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
  mounted() {
    // Abstract router requires initial navigation.
    // Query params are required to respect auth0 screen hints
    this.$router.replace(window.location.pathname + window.location.search);
  },
}).$mount('#login-app');
