// Expected Query Parameters
// Auth0 Query Params

/** Used to indicate which page to render */
export const AUTH0_PROMPT_PARAM_NAME = 'prompt';
/** Used to indicate which page to render. Takes priority over `prompt` */
export const AUTH0_SCREEN_HINT_PARAM_NAME = 'screen_hint';
/** Space separated list of locales used to translate the page */
export const AUTH0_LOCALE_PARAM_NAME = 'ui_locales';
/** Used to prefill a username on any given page */
export const AUTH0_USERNAME_PARAM_NAME = 'login_hint';
