<template>
  <creator-login-wrapper>
    <rs-container>
      <rs-layout class="column animation form-layout">
        <rs-flex shrink :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }">
          <div v-t="'LTK CREATOR'" class="mb-2 platformText" data-comment="Platform"></div>
          <h1
            data-test-id="influencer-login-title"
            v-if="sentFrom === 'influencer_platform'"
            class="primary-heading"
            v-t="'Collaborations Login'"
          ></h1>
          <h1 data-test-id="default-login-title" v-else class="primary-heading" v-t="'Log in'"></h1>
          <div>
            <p class="mt-2 mb-3 welcomeText">
              <span
                data-test-id="influencer-login-text"
                v-if="sentFrom === 'influencer_platform'"
                v-t="'Welcome to Brand Collaborations! Please use your LTK Creator credentials to log in.'"
              ></span>
              <span
                data-test-id="default-login-text"
                v-else
                v-t="'Welcome to LTK Creator, a platform for creators!'"
              ></span>
              <br />
              <span v-t="'If you are a Brand, log into your'"></span>
              <span>&nbsp;</span>
              <a
                href="https://brands.rewardstyle.com/"
                target="_blank"
                rel="noreferrer nofollow"
                v-t="'LTK Connect account'"
              ></a>
            </p>
          </div>
        </rs-flex>
      </rs-layout>
      <div class="mt-3 animation form-layout">
        <login-form />
      </div>
    </rs-container>
  </creator-login-wrapper>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CreatorLoginWrapper from '@/components/CreatorLoginWrapper.vue';
import LoginForm from '@/components/LoginForm.vue';

export default defineComponent({
  components: {
    CreatorLoginWrapper,
    LoginForm,
  },
  props: {
    sentFrom: {
      type: String,
      default: '',
    },
  },
  name: 'LoginPage',
  setup() {
    // NOTE: The logic in the template is backwards, but it's set in stone b/c of redirects. So collabs is influencer_platform and default is influencer/creator login
  },
});
</script>

<style lang="scss" scoped>
.form-layout {
  max-width: 320px;
  margin: auto;
}

.supportHelp {
  cursor: pointer;
  color: #808080;
  font-size: 12px !important;

  &:hover,
  &:focus {
    color: #000;
  }
}

.platformText {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  line-height: 170%;
}

.primary-heading {
  color: #000;
  font-family:
    IvyPrestoDisplay,
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Droid Sans,
    Helvetica Neue;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 1.1;
  margin-bottom: 2rem;
  margin-top: 0;
  display: block;
}

.welcomeText {
  font-size: 14px;
  line-height: 150%;
}

.brandAccountTxt {
  font-weight: 400;
  font-size: 13.5px;
  line-height: 150%;
}

.animation {
  animation: form-slide 0.5s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
