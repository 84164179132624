import { RsForm } from '@rscollabs/rs-ui-library/src/components';
import { TranslateResult } from 'vue-i18n';

type RewardStyleForm = typeof RsForm;

export interface RsFormComponent extends RewardStyleForm {
  validate(): boolean;
}

export interface ConfigParams {
  icon: string;
  assetsUrl: string;
  auth0Domain: string;
  auth0Tenant: string;
  clientConfigurationBaseUrl: string;
  callbackOnLocationHash: boolean;
  callbackURL: string;
  cdn: string;
  clientID: string;
  dict: Auth0Dict;
  extraParams: ExtraParams;
  internalOptions: InternalOptions;
  widgetUrl: string;
  isThirdPartyClient: boolean;
  authorizationServer: AuthorizationServer;
  colors: Colors;
}

export enum routeNames {
  LOGIN = 'login',
  SIGNUP = 'signup',
  FORGOT_PASSWORD = 'forgot-password',
  CONFIRMATION = 'confirmation',
  NOT_FOUND = '404',
}

export interface Auth0Dict {
  signin: Signin;
}

export interface Signin {
  title: string;
}

export interface ExtraParams {
  protocol: string;
  _csrf: string;
  _intstate: string;
  state: string;
}

export interface InternalOptions {
  protocol: string;
  _csrf: string;
  _intstate: string;
  state: string;
}

export interface AuthorizationServer {
  url: string;
  issuer: string;
}

export interface Colors {
  page_background: string;
  primary: string;
}

declare global {
  interface Window {
    configParams: ConfigParams;
  }
}

export interface ErrorHandler {
  showError: boolean;
  message: string | TranslateResult | null;
}
