<template>
  <div class="mt-3 form-animation form-layout">
    <rs-form ref="form" v-model="isValid" @submit.prevent="handleEmailSubmit" id="signup-form">
      <div class="error--text mb-4" v-show="error.showError">
        <span v-html="error.message"></span>
      </div>
      <rs-text-field
        autofocus
        class="mb-4"
        v-model="formData.email"
        :rules="emailRule"
        :label="labels.email"
        required
        data-test-id="signup-email-input"
        :error="error.showError"
        outline
      ></rs-text-field>
      <rs-text-field
        v-model="formData.password"
        :rules="passwordRule"
        :label="labels.password"
        required
        data-test-id="signup-password-input"
        @click:append="visible = !visible"
        :append-icon="visible ? 'visibility' : 'visibility_off'"
        :type="visible ? 'text' : 'password'"
        class="mb-4"
        :error="error.showError"
        outline
      ></rs-text-field>
      <rs-btn
        large
        rounded
        inverted
        data-test-id="signup-submit-btn"
        class="loginBtn"
        id="signup-button"
        type="submit"
        :disabled="isLoggingIn || !isValid"
        :loading="isLoggingIn"
        @click.prevent="handleEmailSubmit"
        >{{ labels.continue }}
      </rs-btn>
    </rs-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import auth0, { Auth0Error, WebAuth, type AuthOptions } from 'auth0-js';
import { useI18n } from 'vue-i18n-bridge';
import { RsFormComponent, ErrorHandler } from '@/types';
import { useRoute } from 'vue-router/composables';

export default defineComponent({
  name: 'SignupForm',
  setup() {
    const vm = getCurrentInstance();
    const { $amplitude } = vm?.proxy as Vue;
    const route = useRoute();

    const { t } = useI18n();

    const visible = ref(false);
    const isLoggingIn = ref(false);
    const isValid = ref(false);
    const form = ref<RsFormComponent | null>(null);
    let webAuth: WebAuth;

    const formData = reactive({
      email: '',
      password: '',
    });

    const error = reactive<ErrorHandler>({
      showError: false,
      message: null,
    });

    const emailRule = computed(() => [
      (v: string) => !!v || t('Email is required'),
      (v: string) => /.+@.+/.test(v) || t('Email is invalid'),
    ]);

    const passwordRule = computed(() => [
      (v: string) => !!v || t('Password is required'),
      (v: string) => v.length >= 8 || t('8 Character minimum'),
      (v: string) => /.*[a-z]/.test(v) || t('Password needs a lowercase'),
      (v: string) => /.*[A-Z]/.test(v) || t('Password needs a uppercase'),
      (v: string) => /.*[^a-zA-Z]/.test(v) || t('Password needs a special character'), // copied rules from sigil
    ]);

    const labels = computed(() => ({
      email: t('Email'),
      password: t('Password'),
      continue: t('Continue'),
      invalidSignup: t('The email you’ve entered is already associated with an account.'),
      invalidPassword: t(
        'Your password did not meet all the requirements. Passwords need to be at least 8 characters and have one uppercase, one lowercase, and one special character.',
      ),
      login: t('Login'),
      here: t('here') /* Specifies where help can be provided */,
      internalError: t(
        'We have encountered an error on our end. If this error persists, please contact customer support by logging a ticket',
      ),
    }));

    const handleError = (err: Auth0Error) => {
      let msg = '';
      if (err) {
        switch (err.code) {
          case 'invalid_signup':
            msg = `${labels.value.invalidSignup} <a href="/?prompt=login" target="_blank">${labels.value.login}</a>`;
            break;
          case 'invalid_password':
            msg = labels.value.invalidPassword;
            break;
          default:
            msg = `${labels.value.internalError} <a href="https://help.rewardstyle.com/" target="_blank"> ${labels.value.here} </a>.`;
        }
      }

      error.message = msg;
      error.showError = true;
    };

    const handleEmailSubmit = async () => {
      if (!isValid.value) return false;

      isLoggingIn.value = true;
      $amplitude?.track('/auth/apply/sign-up/email');

      webAuth?.signup(
        {
          email: formData.email,
          password: formData.password,
          connection: 'InfluencerUser',
        },
        (err) => {
          isLoggingIn.value = false;

          if (err) {
            $amplitude?.track('/auth/signup/failure');
            handleError(err);
          } else {
            $amplitude?.track('/auth/signup/success');
            webAuth?.login(
              {
                realm: 'InfluencerUser',
                username: formData.email,
                password: formData.password,
              },
              (err) => {
                isLoggingIn.value = false;
                if (err) {
                  handleError(err);
                  $amplitude?.track('/auth/login/fail');
                } else {
                  $amplitude?.track('/auth/login/success');
                }
              },
            );
          }
        },
      );
    };

    onMounted(() => {
      let params;

      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        const urlParams = route.query;

        params = {
          domain: process.env.VUE_APP_AUTH_DOMAIN as string,
          clientID: urlParams?.client ?? (process.env.VUE_APP_AUTH_CLIENT_ID as string),
          redirectUri: urlParams?.redirect_uri ?? (process.env.VUE_APP_AUTH_REDIRECT_TO as string),
          responseType: 'code',
        };
        // otherwise, use the decoded the URI component
      } else {
        const auth0Domain = window.configParams.auth0Domain;

        params = Object.assign(
          {
            domain: auth0Domain,
            clientID: window.configParams.clientID,
            redirectUri: window.configParams.callbackURL,
            responseType: 'code',
            overrides: {
              __tenant: window.configParams.auth0Tenant,
              __token_issuer: window.configParams.authorizationServer.issuer,
            },
          },
          window.configParams.internalOptions,
        );
      }
      // eslint-disable-next-line no-undef
      webAuth = new auth0.WebAuth(params as AuthOptions);
    });

    return {
      formData,
      form,
      isLoggingIn,
      isValid,
      handleEmailSubmit,
      error,
      labels,
      visible,
      emailRule,
      passwordRule,
    };
  },
});
</script>

<style lang="scss" scoped>
.form-layout {
  max-width: 420px;
  margin: auto;
}

#signup-form {
  #signup-button {
    background-color: #000;
    border: none !important;
    color: #fff !important;

    &:hover {
      background-color: #222 !important;
      color: #fff;
      border: none !important;
    }

    &:disabled {
      color: #ccc !important;
    }
  }

  .apply__btn {
    color: #000;

    &:hover {
      background-color: #111 !important;
      color: #fff;
      border: none !important;
    }
  }
}

.form-animation {
  animation: form-slide 0.8s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>

<style lang="scss">
.v-btn {
  .v-btn__content {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &:focus {
      border: none;
    }
  }

  &:disabled {
    background: #999;
  }
}

.v-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.005em;
  margin-bottom: 58px;
  color: var(--grey-50) !important;
}

.v-label--active {
  top: -5px;
}

.theme--light.v-text-field--outline:not(.error--text) > .v-input__control > .v-input__slot {
  border-color: var(--grey-50) !important;
}
.v-text-field--box .v-label--active,
.v-text-field--full-width .v-label--active,
.v-text-field--outline .v-label--active {
  transform: translateY(-27px) scale(0.85) !important;
  background-color: white !important;
  padding: 0 4px;
}
</style>
