<template>
  <div class="mt-3 form-animation">
    <rs-form ref="form" lazy-validation @submit.prevent="handleSubmit" id="login-form">
      <div class="error--text mb-4" v-show="error.showError">
        <span v-html="error.message"></span>
      </div>
      <rs-text-field
        autofocus
        class="mb-4"
        v-model="formData.email"
        :rules="emailRule"
        :label="labels.email"
        required
        data-test-id="login-email-input"
        :error="error.showError"
      ></rs-text-field>
      <rs-text-field
        v-model="formData.password"
        :rules="passwordRule"
        :label="labels.password"
        required
        data-test-id="login-password-input"
        @click:append="visible = !visible"
        :append-icon="visible ? 'visibility' : 'visibility_off'"
        :type="visible ? 'text' : 'password'"
        class="mb-4"
        :error="error.showError"
      ></rs-text-field>
      <rs-btn
        large
        rounded
        inverted
        data-test-id="login-submit-btn"
        class="loginBtn"
        id="login-button"
        type="submit"
        :disabled="isLoggingIn"
        :loading="isLoggingIn"
        @click.prevent="handleSubmit"
        >{{ labels.logIn }}
      </rs-btn>
      <rs-btn
        class="mt-2 mb-4 apply__btn"
        large
        @click="clickEventApplyNow"
        data-test-id="request-access-btn"
        :disabled="isLoggingIn"
        >{{ labels.applyNow }}
      </rs-btn>
      <div class="mb-5">
        <a
          @click="clickEventForgotPassword"
          class="forgot-password"
          data-test-id="forgot-password-link"
          v-t="'Forgot Password'"
        ></a>
      </div>
    </rs-form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import auth0, { Auth0Error, WebAuth, type AuthOptions } from 'auth0-js';
import { useI18n } from 'vue-i18n-bridge';
import { RsFormComponent, ErrorHandler } from '@/types';
import { validation } from '@/utils/validation';
import { useRoute } from 'vue-router/composables';

export default defineComponent({
  name: 'LoginForm',
  setup() {
    const vm = getCurrentInstance();
    const { $amplitude } = vm?.proxy as Vue;
    const route = useRoute();

    const { t } = useI18n();

    const visible = ref(false);
    const isLoggingIn = ref(false);
    const form = ref<RsFormComponent>();
    const webAuth = ref<WebAuth>();

    const formData = reactive({
      email: '',
      password: '',
    });

    const error = reactive<ErrorHandler>({
      showError: false,
      message: null,
    });

    const emailRule = computed(() => [
      (v: any) => !!v || t('Email is required'),
      (v: any) => /.+@.+/.test(v) || t('Email is invalid'),
    ]);

    const passwordRule = computed(() => [(v: any) => !!v || t('Password is required')]);
    const forgotPasswordUrl = `${process.env.VUE_APP_SIGIL_URL}/forgot_password/?client_id=UnVU3dMRvktGgVdWJWi05KpKrSVhj6smmfhanudB`;

    const data = {
      databaseConnection: 'InfluencerUser',
      supportTicket: 'https://help.rewardstyle.com/',
      supportEmail: 'mailto:influencersupport@rewardstyle.zendesk.com',
    };

    const labels = computed(() => ({
      email: t('Email'),
      password: t('Password'),
      logIn: t('LOG IN'),
      applyNow: t('APPLY NOW'),
      invalidRecord: t('Sorry, but that email and password combination did not match our records.'),
      accountBlocked: t(
        'Your account has been blocked after multiple consecutive login failures. We’ve sent you an email with instructions on how to unblock your account.',
      ),
      here: t('here'),
      accountDisabled: t('Your account has been disabled. Please contact customer support by logging a ticket'),
      internalError: t(
        'We have encountered an error on our end. If this error persists, please contact customer support by logging a ticket',
      ),
    }));

    const newUrlParams = computed(() => {
      return window.location ? new URLSearchParams(window.location.search) : null;
    });
    const sentFromParams = computed(() => {
      return newUrlParams.value?.get('sentFrom') || false;
    });

    const handleError = (err: Auth0Error | null) => {
      let msg = labels.value.invalidRecord;

      if (err) {
        switch (err.code) {
          case 'access_denied':
            break;
          case 'invalid_user_password':
            break;
          case 'too_many_attempts':
            msg = labels.value.accountBlocked;
            break;
          case 'unauthorized':
            msg = `${labels.value.accountDisabled} <a href="https://help.rewardstyle.com/" target="_blank"> ${labels.value.here} </a>.`;
            break;
          default:
            msg = `${labels.value.internalError} <a href="https://help.rewardstyle.com/" target="_blank"> ${labels.value.here} </a>.`;
        }
      }

      error.message = msg;
      error.showError = true;
    };

    const clickEventApplyNow = () => {
      const event = {
        event_type: '/login/apply-now',
        event_properties: { referrer: 'login' },
      };

      $amplitude?.track(event);
      window.location.href = `${process.env.VUE_APP_LEGACY_HOME_PAGE}/apply/creator`;
    };

    const clickEventForgotPassword = () => {
      const event = {
        event_type: '/auth/login/forgotpassword',
        event_properties: { referrer: 'login' },
      };

      $amplitude?.track(event);
      window.location.href = forgotPasswordUrl;
    };

    const handleSubmit = () => {
      if (!validation(form.value)) {
        return;
      }

      login();
    };

    const login = () => {
      isLoggingIn.value = true;
      const { email, password } = formData;
      $amplitude?.track('/login-attempt');

      webAuth.value?.login(
        {
          realm: data.databaseConnection,
          username: email,
          password,
        },
        (err) => {
          isLoggingIn.value = false;
          $amplitude?.track('/auth/login/fail');
          handleError(err);
        },
      );
    };

    onMounted(() => {
      let params;

      if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
        const urlParams = route.query;

        params = {
          domain: process.env.VUE_APP_AUTH_DOMAIN as string,
          clientID: urlParams?.client ?? (process.env.VUE_APP_AUTH_CLIENT_ID as string),
          redirectUri: urlParams?.redirect_uri ?? (process.env.VUE_APP_AUTH_REDIRECT_TO as string),
          responseType: 'code',
        };
        // otherwise, use the decoded the URI component
      } else {
        const auth0Domain = window.configParams.auth0Domain;

        params = Object.assign(
          {
            domain: auth0Domain,
            clientID: window.configParams.clientID,
            redirectUri: window.configParams.callbackURL,
            responseType: 'code',
            overrides: {
              __tenant: window.configParams.auth0Tenant,
              __token_issuer: window.configParams.authorizationServer.issuer,
            },
          },
          window.configParams.internalOptions,
        );
      }
      // eslint-disable-next-line no-undef
      webAuth.value = new auth0.WebAuth(params as AuthOptions);

      if (sentFromParams.value) {
        newUrlParams.value?.set('sentFrom', sentFromParams.value);
      }
    });

    return {
      formData,
      form,
      isLoggingIn,
      handleSubmit,
      error,
      labels,
      visible,
      emailRule,
      passwordRule,
      forgotPasswordUrl,
      clickEventApplyNow,
      clickEventForgotPassword,
      sentFromParams,
    };
  },
});
</script>

<style lang="scss" scoped>
* {
  font-family:
    SofiaPro,
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Droid Sans,
    Helvetica Neue;
  font-style: normal;
  font-weight: 400;
}

.supportHelp {
  cursor: pointer;
  color: #808080;
  font-size: 12px !important;

  &:hover {
    color: #000;
  }
}

.passwordPrompt {
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}

.platformText {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  line-height: 170%;
}

.primaryHeading {
  color: #000;
  font-family:
    IvyPrestoDisplay,
    sans-serif,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Droid Sans,
    Helvetica Neue;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 1.1;
  margin-bottom: 2rem;
  margin-top: 0;
  display: block;
}

.welcomeText {
  font-size: 14px;
  line-height: 150%;
}

.brandAccountTxt {
  font-weight: 400;
  font-size: 13.5px;
  line-height: 150%;
}
#login-form {
  #login-button {
    background-color: #000;
    border: none !important;
    color: #fff !important;

    &:hover {
      background-color: #222 !important;
      color: #fff;
      border: none !important;
    }

    &:disabled {
      color: #ccc !important;
    }
  }

  .brandLnkText {
    color: #0469e0;
  }

  .apply__btn {
    color: #000;

    &:hover {
      background-color: #111 !important;
      color: #fff;
      border: none !important;
    }
  }

  .forgot-password {
    cursor: pointer;
    font-size: 1.5rem;
    mix-blend-mode: normal;
    text-transform: uppercase;
    font-weight: 700;
    color: #222;
    letter-spacing: 0.08em;
    line-height: 24px;
    margin: 1rem auto auto;
    text-align: center;
    display: block;

    &:hover {
      color: #000;
    }
  }
}

.form-animation {
  animation: form-slide 0.8s ease forwards;
}

@keyframes form-slide {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>

<style lang="scss">
.v-btn {
  .v-btn__content {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &:focus {
      border: none;
      outline: none !important;
    }
  }

  &:disabled {
    background: #999;
  }
}

.v-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.005em;
  margin-bottom: 58px;
  color: #757575 !important;
}

.v-label--active {
  top: -5px;
}
</style>
